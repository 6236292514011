import { render, staticRenderFns } from "./ConnectedIntegrations.vue?vue&type=template&id=85490304&scoped=true&"
import script from "./ConnectedIntegrations.vue?vue&type=script&lang=js&"
export * from "./ConnectedIntegrations.vue?vue&type=script&lang=js&"
import style0 from "./ConnectedIntegrations.vue?vue&type=style&index=0&id=85490304&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85490304",
  null
  
)

export default component.exports